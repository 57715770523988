import { ContentfulMedia } from '~/types/cms/contentfulModel'

export enum EFallbackImageTypes {
  Deafult = 'default',
  NewCar = 'new-car',
  UsedCar = 'used-car',
  NewSmart = 'new-smart',
  UsedSmart = 'used-smart'
}
export interface IResponsiveImage {
  url?: string | any
  width?: number
  height?: number
  srcsetSizes?: number[]
  sizes?: string
  backgroundClass?: string
  srcset?: string
  imageIsPlaceHolder?: boolean
  alt?: string
  fallback?: EFallbackImageTypes
  isNew?: boolean
}
export interface IResponsiveImages {
  title?: string
  desktop?: IResponsiveImage
  mobile?: IResponsiveImage
  default?: IResponsiveImage
}

export interface IContentfulImageSet {
  desktop?: ContentfulMedia
  mobile?: ContentfulMedia
}

export type TImageElement = {
  src?: string
  srcset?: string
  width?: number
  height?: number
}
export type TMobileDesktopImage = {
  default?: TImageElement
  desktop?: TImageElement
  mobile?: TImageElement
  breakpoint?: number
}
